import React from "react";
import {FaRecordVinyl, FaRegEye, FaHandHoldingHeart} from "react-icons/fa";

import HeaderTitle from "../../components/Section-Title";

import './sobre.scss';

import imageAbout from "./pictures/about-1.jpg";
import imageAbout3 from "./pictures/about-1.3.jpg";

export default function Sobre(){
  return(
    <>
      <HeaderTitle name="Sobre Nós" link1={"Home / "} link2={"Sobre"}></HeaderTitle>

      <section id="about-section">
        <div className="container box-about">
          <div className="about-box">
            <figure data-aos="fade-in" data-aos-duration="1000">
              <img src={imageAbout} alt="image about" />
            </figure>
          </div>
          <div className="about-box">
            <div className="desc-about">
              <h1 data-aos="fade-in" data-aos-duration="1000">conheça a <strong data-aos="fade-in" data-aos-duration="1000">locplan</strong></h1>
              <p data-aos="fade-in" data-aos-duration="1000">
                Nascida do sonho de um jovem empreendedor, a <strong>LOCPLAN </strong>
                atende e auxilia quem <strong>E CONSTROI O BRAZIL.</strong>
              </p>
              <p data-aos="fade-in" data-aos-duration="1000">
                Aqui sabemos a importância de contar com um parceiro
                estratégico para seu negócio. Esteja você presente no Agronegócio,
                Construção Civil, Mineração, Infraestrutura, Ferroviárias, Usinas Sucroalcooleiras
                Colheita Florestal, etc.
              </p>
              <p data-aos="fade-in" data-aos-duration="1000">
                Compartilhamos soluções inteligentes e máquinas para você FAZER
                MAIS COM MENOS.
              </p>
              <p data-aos="fade-in" data-aos-duration="1000">
                Somos especialistas em pessoas e máquinas, e nossa estratégia é tornar
                seu negócio mais <strong>COMPETITIVO</strong> mais <striong>EFICIENTE</striong> e mais  <strong>PRODUTIVO</strong>.
              </p>
            </div>
          </div>

        </div>
      </section>

    {/* values section */}
      <section id="values">
        <div className="container">
          <div className="values">
            <div className="values-box-title" data-aos="fade-in" data-aos-duration="1000">
              <h3>valores</h3>
              <h2>nossos valores</h2>
            </div>
            <div className="values-box">
              <div className="box-values" data-aos="fade-in" data-aos-duration="1000">
                <div className="box-icon">
                  <FaRecordVinyl size={40} />
                </div>
                <h3>missão</h3>
                <p>
                  Prover com agilidade e disponibilidade, soluções operacionais e completas,
                  focadas em excelência e otimização de custo ao cliente
                </p>

              </div>
              <div className="box-values" data-aos="fade-in" data-aos-duration="1000">
                <div className="box-icon">
                  <FaRegEye size={40} />
                </div>
                <h3>visão</h3>
                <p>
                 Ser referência no mercado como a melhor empresa de terraplanagem e 
                 locação de equipamentos pesados, superando as necessidades  e 
                 expectativas dos nossos clientes
                </p>

              </div>
              <div className="box-values" data-aos="fade-in" data-aos-duration="1000">
                <div className="box-icon">
                  <FaHandHoldingHeart size={40} />
                </div>
                <h3>valores</h3>
                <p>
                  Excelência, ética, honestidade, 
                  profissionalismo e compromisso
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact section */}
      <section id="section-contact">
        <div className="container box-contact">
          <div className="desc-contact">
            <h2 data-aos="fade-in" data-aos-duration="1000">Estamos ao seu lado</h2>
            <strong data-aos="fade-in" data-aos-duration="1000">prontos para atender à sua necessidade</strong>
          </div>
          <div className="desc-contact">
            <button>
              <a href="#" data-aos="fade-in" data-aos-duration="1000">Entre em contato</a>
            </button>
          </div>
        </div>
      </section>
      
      {/* last section  */}
      <section id="section-info">
        <div className="container box-info">
          <div className="info-box">
            <p data-aos="fade-in" data-aos-duration="1000">
              Entedemos que cada projeto é único. E a maneira de executar
              cada um deles também dever ser. Oferecemos <strong data-aos="fade-in" data-aos-duration="1000">terraplanagem</strong> e 
              <strong data-aos="fade-in" data-aos-duration="1000"> locação de equipamentos pesados</strong> sob medida para você alcançar
              os melhores resultados. No tempo, tamanho e jeito certo.
            </p>
          </div>
          <div className="info-box">
            <figure data-aos="fade-in" data-aos-duration="1000">
              <img src={imageAbout3} alt="image About 2" />
            </figure>
          </div>

        </div>
      </section>

    </>
  )
}