import React from "react";
import Slider from 'react-animated-slider';
import 'react-animated-slider-2/build/horizontal.css';
import 'normalize.css/normalize.css';

import './slider.scss';
import './slider-animation.scss';



export default function Slide(){
  
  const content = [
    {
      title: 'NÃO DEIXE QUE A FALTA DE EQUIPAMENTOS PESADOS ATRASE SUA OBRA',
      a: 'ALUGUE AGORA',
      image: 'https://locplanpa.com.br/pictures/slider-1.jpg',
    },
    {
      title: 'O PRIMEIRO PASSO PARA UMA OBRA DE SUCESSO: TERRAPLANAGEM COM A LOCPLAN',
      a: 'AGENDE AGORA',
      image: 'https://locplanpa.com.br/pictures/slider-2.jpg',
    },
    {
      title: 'GARANTA O SUCESSO DA SUA OBRA COM A SOLUÇÃO COMPLETA DE TERRAPLANAGEM E ALUGUEL DE EQUIPAMENTOS DA LOCPLAN',
      a: 'GARANTIR SOLUÇÃO',
      image: 'https://locplanpa.com.br/pictures/slider-3.jpg',
    },
  ]

  return (
    <>
    <section className="wrapper">
      <Slider className="slider-wrapper" autoplay={2000} >
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{background: `url('${item.image}') no-repeat center center`}}
          >
            <div className="inner container">
              <h1>{item.title}</h1>
              <a className="button-slider" href="https://api.whatsapp.com/send?phone=5555949254329&text=Ol%C3%A1,%20estou%20vindo%20do%20site,%20pode%20me%20ajudar??%20" target="_blank">{item.a}</a>
            </div>
          </div>
        ))}
      </Slider>

    </section>
    </>
  )

}