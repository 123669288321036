import React from "react";
import { FaHome, FaTractor, FaPhoneAlt } from 'react-icons/fa';
import { MdPeopleAlt, MdLocationOn, MdMail } from 'react-icons/md';
import { IoMdContact } from 'react-icons/io';

import './footer.scss';

// Logo
import logo from './LOGO-2.png';

export default function Footer(){

  return(
    <>
      <section id="footer">
        <div className="container footer">
          <div className="footer-box">
            <figure
             data-aos="fade-in"
             data-aos-duration="1000"
            >
              <img src={logo} alt="logo locplan" />
            </figure>
            <p
             data-aos="fade-in"
             data-aos-duration="1000"
            >
              Com a Locplan, você tem acesso aos melhores equipamentos e profissionais do mercado para tornar sua obra um sucesso.
            </p>
          </div>

          <div className="footer-box">
             <div
               data-aos="fade-in"
               data-aos-duration="1000"
              >
              <h2>Links Úteis</h2>
            </div>
              <ul data-aos="fade-in" data-aos-duration="1000">
                <li>
                  <FaHome size={25} />
                  <a href="/">Home</a>
                </li>
                <li>
                  <MdPeopleAlt size={25} />
                  <a href="/sobre">Sobre</a>
                </li>
                <li>
                  <FaTractor size={25} />
                  <a href="/servicos">Serviços</a>
                </li>
                <li>
                  <IoMdContact size={25} />
                  <a href="/contato">Contato</a>
                </li>
              </ul>
          </div>
          <div className="footer-box">
            <div
            data-aos="fade-in"
            data-aos-duration="1000"
            >
              <h2>Contatos</h2>
            </div>
              <ul data-aos="fade-in" data-aos-duration="1000">
                <li>
                  <MdLocationOn size={25} />
                  <a href="#"> Av: Carajás Q.214 LT.26, 27, 28 - Nova Carajás Parauapebas - PA</a>
                </li>
                <li>
                  <FaPhoneAlt size={25} />
                  <a href="">(94) 99254-3298</a>
                </li>
                <li>
                  <MdMail size={25} />
                  <a href="#"> contato@locplanpa.com.br</a>
                </li>
              </ul>
          </div>
        </div>

      </section>
    </>
  )

}