import React from "react";
import { Link } from "react-router-dom";

import './index.scss';

export default function HeaderTitle({children, name, link1, link2}){
  return(
    <>
      <section className="section-about">
        <div className="container">
          <div className="box-center">
            {children}
            <span>
              {name}
            </span>
            <div className="links">
              {children}
              <Link to="/">{link1}</Link>
              <Link>{link2}</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}