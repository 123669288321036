import React from "react";
import { Link } from "react-router-dom";
import {  MdMail, MdPhone, MdLocationOn} from 'react-icons/md';

import './header.scss';

export default function Header(){
  return(
    <>
      <section id="header">
        <div className="container">
          <div className="header">
            <div className="box-icons" id="box-one">
              <a href="mailto:contato@locplanpa.com.br">
                <MdMail size={25} />
                contato@locplanpa.com.br
              </a>
            </div>
            <div className="box-icons" id="box-two">
              <a href="https://api.whatsapp.com/send?phone=559492543298" target="_blank">
                <MdPhone size={25} />
                (94) 99254-3298
              </a>
            </div>
            <div className="box-icons" id="box-three">
              <a href="#">
                <MdLocationOn size={25} />
                Av: Carajás Q.214 LT.26, 27, 28 - Nova Carajás Parauapebas - PA
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}