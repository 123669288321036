
import photo1 from "./pictures/escavadora.png";
import photo2 from "./pictures/trator.png";

import HeaderTitle from "../../components/Section-Title";

import './servicos.scss';

export default function Servicos(){

  const images = [ photo1, photo2];

  return(
    <>
      <HeaderTitle name="Serviços" link1={"Home / "} link2={"Serviços"}></HeaderTitle>

      <section id="services">
        <div className="container services">
          <div className="box-services">
            <figure>
              <img src={images[0]} alt="escavadora" width={50}/>
            </figure>
            <h2>Terreno nivelado, construção segura</h2>
            <p>Investir em um serviço de terraplanagem é a garantia de uma construção bem-sucedida. Com nossa experiência e conhecimento, podemos oferecer soluções personalizadas que atendem às suas necessidades e garantem a segurança da obra.</p>
          </div>
          <div className="box-services">
            <figure>
              <img src={images[1]} alt="escavadora" width={50}/>
            </figure>
            <h2>Alugue com quem entende de equipamentos</h2>
            <p>Não comprometa a segurança dos seus colaboradores e a qualidade da sua obra com equipamentos inadequados ou mal conservados. Alugue com a Locplan e tenha a certeza de ter equipamentos de qualidade superior e em perfeito estado.</p>
          </div>
          {/* <div className="box-services">
            <figure>
              <img src={images[0]} alt="escavadora" width={50}/>
            </figure>
            <h2>Serviços de Terraplanagem</h2>
            <p>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500</p>
          </div> */}
        </div>

      </section>

      <section id="info-services">
        <div className="container info-services">
          <h2>A terraplanagem e a locação de equipamentos são serviços fundamentais para uma obra de qualidade</h2>
          <p>
          Na Locplan, oferecemos as soluções ideais para atender as necessidades específicas do seu projeto, garantindo a segurança e qualidade do processo. Com equipamentos modernos e uma equipe de profissionais experientes, estamos prontos para ajudá-lo a construir seus sonhos.
          </p>
          <button><a href="https://api.whatsapp.com/send?phone=5555949254329&text=Ol%C3%A1,%20estou%20vindo%20do%20site,%20pode%20me%20ajudar??%20" target="_blank">SOLICITE UM ORÇAMENTO AGORA</a></button>
        </div>
      </section>
    </>
  )
}