import React from "react";
import { FaTractor } from 'react-icons/fa';

import Fancybox from './fancybox'; 


import './fancybox.scss';

export default function Galery(){

  const newGalery = 
    <p className="img-fancy">
      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/1.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/1.jpg"
         />
      </a>
      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/2.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/2.jpg"
        />
      </a>

      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/3.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/3.jpg"
        />
      </a>

      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/4.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/4.jpg"
        />
      </a>

      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/5.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/5.jpg"
        />
      </a>

      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/6.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/6.jpg"
        />
      </a>

      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/7.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/7.jpg"
        />
      </a>

      <a data-fancybox="gallery" href="https://locplanpa.com.br/pictures/equipment/1024x768/8.jpg">
        <img
          data-aos="fade-in"
          data-aos-duration="1000"
          alt=""
          src="https://locplanpa.com.br/pictures/equipment/300x320/8.jpg"
        />
      </a>
    </p>;


  return(

    <>
      <section id="galery">
        <div className="container galery">
          <div className="galery-desc">
            <strong>Equipamentos modernos e seguros para sua obra</strong>

            <div className="galery-title">
              <FaTractor size={35} color="#121212"/>
              <h1>Nossos Equipamentos</h1>
            </div>
          </div>
          
          <div className="galery-photos">
            <div className="fancyimg">
              <Fancybox className="img-fancy">
                {/* Galery  */}
                {newGalery}
              </Fancybox>
            </div>
          </div>
          
        </div>
      </section>
    </>
  )

}