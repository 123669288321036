import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Sobre from "../pages/Sobre";
import Servicos from "../pages/Servicos";
import Contato from "../pages/Contato";

export default function Routes(){
  return(
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/sobre" component={Sobre} />
      <Route exact path="/Servicos" component={Servicos} />
      <Route  exact path="/Contato" component={Contato} />
    </Switch>
  )
}