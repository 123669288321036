import React from "react";
import Slide from "../../components/Slider";

// Accordions
import  Accordion  from "@mui/material/Accordion";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';

// Logo
import image from './pictures/locplan.jpg';

// clients pictures
import topgeo from './pictures/clients/topgeo.png';
import seculos from './pictures/clients/seculos.png';
import geomix from './pictures/clients/geomix.png';
import agmix from './pictures/clients/agmix.png';
import terraco from './pictures/clients/terraco.png';

// logo 2
import logo2 from './pictures/LOGO-2.png'

// Galery Fancybox
import Galery from "./fancybox";

// Google Maps
import Maps from "../../components/Maps";

// Formik
import { useFormik } from 'formik'

// Photo Profile
import photoProfile from './pictures/profile.jpg';

// CSS Style
import './home.scss';

// variable clients
const logoClients = 
  <div className="logo-clients">
    <figure
     data-aos="fade-in"
     data-aos-duration="1000"
    >
      <img src={topgeo} alt="topgeo" width={220} />
    </figure>
    <figure
     data-aos="fade-in"
     data-aos-duration="1000"
    >
      <img src={seculos} alt="seculos" width={220} />
    </figure>
    <figure
     data-aos="fade-in"
     data-aos-duration="1000"
    >
      <img src={geomix} alt="geomix" width={220} />
    </figure>
    <figure
     data-aos="fade-in"
     data-aos-duration="1000"
    >
      <img src={agmix} alt="agmix" width={220} />
    </figure>
    <figure
     data-aos="fade-in"
     data-aos-duration="1000"
    >
      <img src={terraco} alt="terraco" width={220} />
    </figure>
  </div>;

// Accordions 
const newAccordion = 
  <div className="accordions-sumary">
    <Accordion className="accordions-box">
      <AccordionSummary
        expandIcon={<AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h2>Aluguel de Equipamentos</h2>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          Coma a possibilidade de alugar os equipamentos pelo tempo necessário, é possível se adaptar a
          imprevistos e mudanças no cronograma. Isso garante mais agilidade e eficiência no processo construtivo.
        </p>
      </AccordionDetails>
    </Accordion>
    <Accordion className="accordions-box">
      <AccordionSummary
        expandIcon={<AddIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <h2>Os melhores equipamentos de construção do mercado</h2>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          Equipamentos de construção de alta qualidade com as marcas líderes Volvo e XCMG.
        </p>
      </AccordionDetails>
    </Accordion>
    <Accordion className="accordions-box">
      <AccordionSummary
        expandIcon={<AddIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <h2>A importância da terraplanagem para sua obra</h2>
      </AccordionSummary>
      <AccordionDetails>
        <p>
          A terraplanagem é fundamental para garantir que sua obra seja concluída dentro do prazo e do
          orçamento estabelecidos. Com ela, é possível evitar retrabalhos e outros que podem atrasar o 
          andamento da construção.
        </p>
      </AccordionDetails>
    </Accordion>
  </div>

  const photoAccordion = 
    <figure>
      <img src={photoProfile} alt="Photo Profile Locplan" />
    </figure>

  // Forms Formik
  const validateForm = values =>{
    const errorMsg = "Minimo de 20 caracteres";
    const errorMessage = "Mensagem deve ter minimo de 40 caracteres";
    const errors = {};

    if(!values.name){
      errors.name = "Required";
    }else if(values.name.length > 20){
      errors.name = errorMsg;
    }

    if(!values.message){
      errors.message = "Required";
    }else if(values.message.length > 40){
      errors.message = errorMsg;
    }

  }


  export default function Home(){
  // Const Formir Forms
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      city: "",
      message: ""
    },
    validateForm,
    onSubmit: values =>{
      alert(JSON.stringify(values, null, 5));
    }
  })

  return(
    <>
      <Slide />

      <section id="about">
        <div className="container">
          <div className="box-about">
            <div className="desc-about">
              <div
               className="title-about"
               data-aos="fade-in"
               data-aos-duration="1000"
              >
                <h3>Bem vindo a <span style={{color: '#FFC100'}}>LOC</span><span>PLAN</span></h3>
                <h2>quem somos</h2>
              </div>
              <div
               className="text-about"
               data-aos="fade-in"
               data-aos-duration="1000"
              >
                <p>
                Nascida do sonho de um jovem empreendedor, a LOCPLAN atende e auxilia quem PRODUZ E CONSTROI O BRASIL.
                Aqui, sabemos a importância de contar com um parceiro estratégico para seu negócio. 
                Esteja você presente no Agronegócio, Construção Civil, Mineração, Infraestrutura, Colheita Florestal, etc.
                </p>
                <button>
                  <a href="#" target="_blank">Conheça nossa hitória</a>
                </button>
              </div>
            </div>
            <div className="desc-about">
              <figure data-aos="fade-in" data-aos-duration="1000">
                <img src={image} alt="LOCPLAN TERRAPLAGEM" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section id="section-two">
        <div className="container section-two">
          <div
           className="box-title"
           data-aos="fade-in"
           data-aos-duration="1000"
          >
            <h1>Atuando com responsabilidade e competência</h1>
          </div>
          <div className="box-inside">
            <div
             className="box-1"
             id="box-1"
             data-aos="fade-in"
             data-aos-duration="1000"
            >
              <div
               data-aos="fade-in"
               data-aos-duration="2000"
              >
                <h2>Aluguel máquinas modernas e seguras</h2>
              </div>
              <div
               data-aos="fade-in"
               data-aos-duration="2000">
                <p>Nossos equipamentos são rigorosamente revisados para garantir a segurança e a produtividade da sua construção. 
                  Conte com a Locplan e realize sua obra com tranquilidade.
                </p>
                <button
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  <a href="/servicos">Saiba Mais</a>
                </button>
              </div>
            </div>
            <div
             className="box-1"
             id="box-2"
             data-aos="fade-in"
             data-aos-duration="1000"
            >
              <div data-aos="fade-in" data-aos-duration="2000">
                <h2>Solução completa em terraplanagem</h2>
              </div>
              <div data-aos="fade-in" data-aos-duration="2000">
                <p>
                  Garantimos uma terraplanagem eficiente e segura para o sucesso da sua construção. 
                  Oferecemos serviços especializados para nivelamento de terrenos, preparação de solos e muito mais.
                </p>
                <button data-aos="fade-in" data-aos-duration="2000">
                  <a href="/servicos">Saiba Mais</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="clients">
        <div className="container clients">
          <div
           data-aos="fade-in"
           data-aos-duration="1000"
          >
            <span>&nbsp;</span>
            <strong>&nbsp;Confiança &amp; Qualidade</strong>
            <h1>&nbsp;Principais clientes</h1>
          </div>
          {/* Clients */}
          {logoClients}
        </div>
      </section>

      <section id="bg-desc">
        <div className="container bg-desc-flex">
          <div
           className="bg-title"
           data-aos="fade-in"
           data-aos-duration="1000"
          >
            <h1>Um novo conceito em Terraplanagem e Locação de Equipamentos Pesados</h1>
          </div>
          <div
           data-aos="fade-in"
           data-aos-duration="1000"
          >
            <figure>
              <img src={logo2} alt="logo 2 locplan" />
            </figure>
          </div>
        </div>
      </section>

      <Galery />

      <section id="accordions">
        <div className="container accordions">
          <div
           className="accordions-desc"
           data-aos="fade-in"
           data-aos-duration="1000"
          >
            <strong>A locação de equipamentos é uma ótima opção para empresas que buscam otimizar
              seus custos, evitando gatos com aquisição e manutenção de equipamentos
            </strong>

            <div className="accordions-title">
              <h1>Otimize seus custos</h1>
            </div>
          </div>

          <div
           className="accordions-profile"
           data-aos="fade-in"
           data-aos-duration="1000"
          >
            {/* Accordions */}
            {newAccordion}

            <div className="accordions-photo">
              {photoAccordion}
            </div>
          </div>
        </div>
      </section>

      <section id="forms">
        {newForm(formik)}
      </section>

    </>
  )
}

function newForm(formik) {
  return <div className="container forms">
    <div className="forms-box">
      <div
       className="forms-desc"
       data-aos="fade-in"
       data-aos-duration="1000"
       >
        <h2>Conheça a Locplan pessoalmente</h2>
        <p>
          Visite nossa emprsa hoje e descubra como podemos ajudá-lo a construir seus sonhos.
        </p>
      </div>
      <div
       className="forms-desc"
       data-aos="fade-in"
       data-aos-duration="1000"
      >
        <Maps />
      </div>
    </div>

    {/* Forms - Formik */}
    <div className="forms-box">
      <div
       className="forms-desc"
       data-aos="fade-in"
       data-aos-duration="1000"
      >
        <h2>Entre em contato</h2>
      </div>
      <div
       className="form-one"
       data-aos="fade-in"
       data-aos-duration="1000"
      >
        <form onSubmit={formik.handleSubmit}>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Digite seu nome"
            onChange={formik.handleChange}
            value={formik.values.name} />
          {formik.errors.name ? <div>{formik.errors.name}</div> : null}
          <br />

          <br />
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Digite seu e-mail"
            onChange={formik.handleChange}
            value={formik.values.email} />
          {/* {formik.errors.email ? <div>{formik.errors.email}</div> : null} */}
          <br />

          <br />
          <input
            id="phone"
            name="phone"
            type="tel"
            placeholder="Digite seu telefone"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            onChange={formik.handleChange}
            value={formik.values.phone} />
          <br />

          <br />
          <input
            id="cidade"
            name="cidade"
            type="text"
            placeholder="Digite sua cidade"
            onChange={formik.handleChange}
            values={formik.values.city} />
          <br />

          <br />
          <textarea
            id="message"
            name="messge"
            type="text"
            placeholder="Digite sua menssagem"
            onChange={formik.handleChange}
            value={formik.values.message}
          ></textarea>
          {formik.errors.message ? <div> {formik.errors.message} </div> : null}

          <button type="submit"><a href="#" target="_blank">Enviar</a></button>

        </form>
      </div>
    </div>
  </div>;
}
