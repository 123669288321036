import { BrowserRouter} from 'react-router-dom';

import Header from './components/Title';
import Mobile from './components/Mobile';
import Footer from './components/Footer';

import Routes from './routes';

export default function App() {
  
  
  return (
    <BrowserRouter>
      <Header/>
      <Mobile/>
      <Routes/>
      <Footer />
    </BrowserRouter>
  );
}
