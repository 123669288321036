


import { MdLocationOn, MdMail, MdSmartphone } from 'react-icons/md';

import HeadeTitle from "../../components/Section-Title";
import Maps from "../../components/Maps";

import { useFormik } from 'formik';

import './contato.scss';

// ValidateForms Formik
const validateForm = values =>{
  const errorMsg = "Minimo de 20 caracteres";
  const errorMessage = "Mensagem deve ter minimo de 60 caracteres";
  const errors = {};

  if(!values.firstName){
    errors.firstName = "Required";
  }else if(values.firstName.length > 20){
    errors.firstName = errorMsg;
  }

  if(!values.message){
    errors.message = "Required";
  }else if(values.message.length > 60){
    errors.message = errorMessage;
  }
}

// Fucntion Forms

function newForm(formik) {
  return  <div
            className="form-two"
            //  data-aos="fade-up"
            //  data-aos-duration="1000"
            >
        <form onSubmit={formik.handleSubmit}>
          <input
            id="firstname"
            name="firstname"
            type="text"
            placeholder="NOME"
            onChange={formik.handleChange}
            value={formik.values.firstName} />
          {formik.errors.firstName ? <div>{formik.errors.firstName}</div> : null}

          <input
            id="lastname"
            name="lastName"
            type="text"
            placeholder="SOBRENOME"
            onChange={formik.handleChange}
            value={formik.values.lastName} />
          {formik.errors.lastName ? <div>{formik.errors.lastName}</div> : null}
          <br />

          <br />
          <input
            id="phone"
            name="phone"
            type="tel"
            placeholder="TELEFONE"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            onChange={formik.handleChange}
            value={formik.values.phone} 
          />

          <input 
            id="email"
            name="email"
            type="text"
            placeholder="E-MAIL"
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          <br/>
          <br/>

          <textarea
            id="message"
            name="messge"
            type="text"
            placeholder="DIGITE SUA MENSAGEM"
            onChange={formik.handleChange}
            value={formik.values.message}
          ></textarea>
          {formik.errors.message ? <div> {formik.errors.message} </div> : null}
          
          <button type="submit"><a href="#" >enviar</a></button>

        </form>
      </div>
}

export default function Contato(){

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: ""
    },
    validateForm,
    onSubmit: values =>{
      alert(JSON.stringify(values, null, 5));
    }
  })

  return(
    <>
      <HeadeTitle name="Contato" link1={"Home / "} link2={"Contato"}></HeadeTitle>

      <section id="contact">
        <div className="container contact">
          <div className="contact-section">
            <div className="contact-maps">
              <Maps />
            </div>
          </div>
          <div className="contact-section">
            <div className="contact-box">
              <h2>Mais Informações</h2>
            </div>
            <div className="contact-box">
              <MdLocationOn />
              <strong>
                <a href="#">Av: Carajás Q.214 LT.26, 27, 28 - Nova Carajás Parauapebas - PA</a>
              </strong>
            </div>
            <div className="contact-box">
              <MdMail />
              <strong>
                <a href='mailto:contato@locplanpa.com.br'>contato@locplanpa.com.br</a>
              </strong>
            </div>
            <div className="contact-box">
              <MdSmartphone />
              <strong>(94) 98476-5214</strong>
            </div>
          </div>
        </div>
      </section>

      <section id="form">
        <div className="container form">
          <div className="form-box">
            <h2>Precisando de ajuda para realizar seu projeto?</h2>
            <p>Entre em contato com a locplan e deixe-nos oferecer as soluções ideais para a sua necessidade.
            </p>
          </div>
          <div className="form-box">
            <div>
              {newForm(formik)}
            </div>
          </div>
        </div>

      </section>

    </>
  )
}