
import { useState } from 'react';
import { Link } from "react-router-dom";
import { FaHome, FaTractor, FaBars } from "react-icons/fa"
import { BsPeopleFill } from "react-icons/bs";
import { IoIosContact } from "react-icons/io";

import './mobile.scss';
import logo from './logo.png';

export default function Mobile(){

  const [isNavExpanded, setIsNavExpanded] = useState(false);


  return(

    <>
      <header id="mobile">
        <div className="container">
          <nav className="navigation">
            {/* logo */}
            <Link to="/" className="logo">
              <figure>
                <img  src={logo} alt="logo" />
              </figure>
            </Link>

            {/* button hamburger */}
            <button
              className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <FaBars size={20} color="black" />
            </button>

            {/* navigation */}
            <div className={ isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
              <ul>
                <li>
                  <Link to="/" >
                    <FaHome size={25} />
                    HOME
                  </Link>
                </li>
                <li>
                  <Link to="/sobre">
                    <BsPeopleFill size={25} />
                    SOBRE
                  </Link>
                </li>
                <li>
                  <Link to="/servicos">
                    <FaTractor size={25} />
                    SERVIÇOS
                  </Link>
                </li>
                <li>
                  <Link to="/contato">
                    <IoIosContact size={25} />
                    CONTATO
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}